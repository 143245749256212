import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import FullPageSection from '../components/FullPageSection'
import HeroTitle from '../components/HeroTitle'
import Footer from '../components/FooterSpanish'
import Header from '../components/HeaderSpanish'

import bgImage from '../img/tlc-issues.jpg'
import tlcImage from '../img/about.png'
import Script from 'react-load-script'

const IssuesPage = (props) => (
  <main>
    <Header className="bg-white" />

    <FullPageSection
      className="overlay-blue align-items-end align-items-lg-center d-flex splash"
      bgImage={bgImage}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <HeroTitle
              className="mt-5 pt-5 pb-3"
              textArray={[
                'Juntos, detendremos al sistema carcelario,',
                'crearemos una economía digna, y haremos',
                'un Nuevo Acuerdo Verde para Nueva York.',
                'Vota • Martes 22 de junio',
              ]}
            />
          </Col>
        </Row>
      </Container>
    </FullPageSection>
    <div className="mt-3 pt-5 pb-5 mb-5">
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <h2 className="mb-3">Nuestra Visión</h2>
            <p>
              Como Miembro del Consejo Municipal, yo me comprometeré a
              transicionar nuestras inversiones en el sistema carcelario e
              invertir en lo que realmente mantiene a las comunidades seguras y
              triunfantes. Lucharé por las familias trabajadoras que han
              construido nuestra ciudad. Impulsaré políticas que no sólo
              protegerán a los neoyorquinos, sino que también crearán una ciudad
              más segura y habitable por décadas. Y co-gobernaré cada día hombro
              con hombro al lado de mis constituyentes.
            </p>
            <p>Para lograr esto, yo trabajaré con estas prioridades:</p>

            <h3 className="color-base1 pt-4 pb-3 font-2">
              Ponerle fin al sistema carcelario de Nueva York y reducir los
              fondos y desbandar al NYPD, cerrando Rikers Island
              permanentemente, deteniendo la construcción de nuevas cárceles, e
              instaurando prácticas restaurativas dentro de todas las agencias
              de la ciudad.
            </h3>

            <p>
              Durante mi década trabajando como defensora pública en la Ciudad
              de Nueva York, yo he visto de frente el trauma que nuestro sistema
              penal genera todos los días en las comunidades de color, pobres e
              inmigrantes. Yo he luchado por mucho tiempo por — y sigo
              comprometida en — lograr una transformación completa sobre nuestro
              sistema penitenciario, y ponerle fin al sistema policial actual y
              el encarcelamiento masivo.
            </p>

            <h3 className="color-base1 pt-4 pb-3 font-2">
              Invertir en las cosas que mantienen seguras a las comunidades
              financiando completamente a nuestras escuelas y acabando con su
              segregación; construyendo nuevas viviendas sociales; protegiendo y
              expandiendo las oportunidades de las pequeñas empresas; y acabando
              con los desiertos de transporte.
            </h3>

            <p>
              Yo soy el producto de escuelas públicas y privadas en Queens, una
              niña que creció en Woodside Houses, y una orgullosa apoyadora de
              las diversas pequeñas empresas de nuestro distrito. En nuestra
              lucha por desinvertir en la opresión policíaca y el
              encarcelamiento, debemos priorizar la inversión en los recursos y
              la infraestructura que estabilizarán nuestras vidas y fortalecerán
              a nuestras comunidades.
            </p>

            <h3 className="color-base1 pt-4 pb-3 font-2">
              Establecer una economía digna en la que los más vulnerables entre
              nosotros cuenten con los recursos que necesitan para
              verdaderamente triunfar, y en la que la fuerza laboral de la
              ciudad cuente con dignidad, salarios adecuados, y protecciones
              expandidas.
            </h3>

            <p>
              Yo soy la hija de una trabajadora doméstica que cuidaba de los
              niños de mi vecindario, y de un trabajador retirado de la Compañía
              de Elevadores Otis. Yo he visto personalmente cómo importa tener
              una infraestructura fuerte y robusta para impulsar la calidad de
              vida económica de nuestra ciudad, y cómo las protecciones
              laborales, las prestaciones sociales, y la membresía sindical
              pueden sacar a una familia de la pobreza.
            </p>

            <h3 className="color-base1 pt-4 pb-3 font-2">
              Implementar un Nuevo Acuerdo Verde para Nueva York creando empleos
              ecológicos y sindicalizados, incrementando los espacios verdes
              públicos, haciendo que nuestra infraestructura sea más limpia, y
              garantizando que las costas de la ciudad sean más resilientes.
            </h3>

            <p>
              Los residentes del Oeste de Queens vieron durante la supertormenta
              Sandy que la Ciudad de Nueva York es particularmente vulnerable a
              los daños ecológicos, sociales y económicos del cambio climático.
              Yo reconozco la necesidad de soluciones que estén a la altura de
              la crisis que hoy enfrentamos, y la importancia de utilizar un
              enfoque orientado en la justicia para transformar a nuestra ciudad
              y elevar a las comunidades que encaran los mayores riesgos.
            </p>

            <Script url="https://static.airtable.com/js/embed/embed_snippet_v1.js" />
            <iframe
              class="airtable-embed airtable-dynamic-height"
              src="https://airtable.com/embed/shrPiKsx5S857SLsi?backgroundColor=gray"
              frameborder="0"
              onmousewheel=""
              width="100%"
              height="1015.005682"
              style={{ background: 'transparent', border: '1px solid #ccc' }}
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer className="mt-5" />
  </main>
)

export default IssuesPage
